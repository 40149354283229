import { css, Keyframes, keyframes, Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from '@txt/core.styles';
import * as React from 'react';

type Props = {
  text?: React.ReactNode;
  textColor?: string;
  asOverlay?: boolean;
} & LoadingSpinnerProps;

/**
 * Simple loading indicator with optional text.
 */
export const LoadingIndicator: React.FC<Props> = ({ text, asOverlay, ...props }) => {
  const theme = useTheme();

  const actual = (
    <Styles.Wrap data-testid="loading-indicator" size={props.size ?? 'default'}>
      <LoadingSpinner {...props} />
      {text && (
        <Styles.Text color={props.colorInverted ? theme.palette.white : (props.textColor ?? undefined)}>
          {text}
        </Styles.Text>
      )}
    </Styles.Wrap>
  );

  if (asOverlay) {
    return <Styles.OverlayWrap>{actual}</Styles.OverlayWrap>;
  }

  return actual;
};

const Styles = {
  Wrap: styled.div<{ size: SizePreset }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: ${(props) => (props.size === 'small' ? 0 : 2)}em 0;

    /* Forcing this thing to always center wherever its placed. */
    width: 100%;
    flex: 1;
  `,
  Text: styled.span<{ color?: string }>`
    margin-top: 1em;
    ${(props) => props.color && `color: ${props.color}`}
  `,
  OverlayWrap: styled(Flex)`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);
  `,
};

type BackgroundPreset = 'white' | 'grey' | 'text' | 'blue';
type SizePreset = 'default' | 'small';

type LoadingSpinnerProps = {
  backgroundPreset?: BackgroundPreset;
  /** Will override backgroundPreset if provided */
  backgroundColor?: string;
  colorInverted?: boolean;
  size?: SizePreset;
  className?: string;
};

const colorPresets = (theme: Theme): Record<BackgroundPreset, string> => ({
  white: theme.palette.white,
  grey: theme.palette.grey[50],
  text: theme.palette.text,
  blue: theme.palette.blue[50],
});

const sizePresets: Record<SizePreset, number> = {
  default: 32,
  small: 16,
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  backgroundPreset = 'white',
  backgroundColor,
  colorInverted = false,
  size = 'default',
  className,
}) => {
  const theme = useTheme();
  const borderColor = backgroundColor ? backgroundColor : colorPresets(theme)[backgroundPreset];
  const squareColor = theme.palette.primary[500];

  const squareProps = {
    borderColor: colorInverted ? squareColor : borderColor,
    squareColor: colorInverted ? borderColor : squareColor,
    size,
  };

  return (
    <TxtureLoadingStyles.Wrap className={className} size={sizePresets[size]}>
      <TxtureLoadingStyles.Square css={square1} {...squareProps} />
      <TxtureLoadingStyles.Square css={square2} {...squareProps} />
      <TxtureLoadingStyles.Square css={square3} {...squareProps} />
    </TxtureLoadingStyles.Wrap>
  );
};

const square1 = css`
  top: 33%;
  animation-delay: 400ms;
`;

const square2 = css`
  top: 0;
  animation-delay: 200ms;
`;

const square3 = css`
  top: -33%;
`;

const animationPresets: Record<SizePreset, Keyframes> = {
  default: keyframes`
  0% { transform: rotateX(54deg) rotateY(0deg) rotateZ(-45deg) translateZ(-2px) }
  50% { transform: rotateX(54deg) rotateY(0deg) rotateZ(45deg) translateZ(4px); }
  100% { transform: rotateX(54deg) rotateY(0deg) rotateZ(45deg) translateZ(-2px); }
`,
  small: keyframes`
  0% { transform: rotateX(54deg) rotateY(0deg) rotateZ(-45deg) translateZ(-.5px) }
  50% { transform: rotateX(54deg) rotateY(0deg) rotateZ(45deg) translateZ(1px); }
  100% { transform: rotateX(54deg) rotateY(0deg) rotateZ(45deg) translateZ(-.5px); }
`,
};

const TxtureLoadingStyles = {
  Wrap: styled.div<{ size: number }>`
    position: relative;
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
    margin: ${(props) => props.size / 4}px;
  `,
  Square: styled.div<{ borderColor: string; squareColor: string; size: SizePreset }>`
    position: absolute;
    left: 0;
    width: ${(props) => sizePresets[props.size]}px;
    height: ${(props) => sizePresets[props.size]}px;
    box-shadow: 0 0 0 ${(props) => sizePresets[props.size] / 6}px ${(props) => props.borderColor};
    background: ${(props) => props.squareColor};
    border-radius: 4px;
    transform: rotateX(54deg) rotateY(0deg) rotateZ(-45deg);

    animation-duration: 1200ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-name: ${(props) => animationPresets[props.size]};
  `,
};
