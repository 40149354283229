import * as React from 'react';
import { useDebounce } from 'react-use';

export function useDebouncedState<T>(initialValue: T, delayInMs: number, onChange?: (value: T) => void) {
  const [value, setValue] = React.useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = React.useState<T>(initialValue);

  useDebounce(
    () => {
      setDebouncedValue(value);
      if (value !== debouncedValue) {
        onChange?.(value);
      }
    },
    delayInMs,
    [value],
  );

  return [value, debouncedValue, setValue] as const;
}
