import styled from '@emotion/styled';
import { Flex } from '@txt/core.styles';
import * as React from 'react';
import { FormattedMessage } from './i18n';

export type Status = 'info' | 'success' | 'warning' | 'error';

export const isStatus = (v: string | null | undefined): v is Status => {
  return v === 'info' || v === 'success' || v === 'warning' || v === 'error';
};

type Props = {
  status?: Status;
  children?: React.ReactNode;
  className?: string;
};

export const StatusDot: React.FC<Props> = ({ status = 'info', children, className }) => (
  <Flex alignItems="center" gap="2" className={className}>
    <Styles.Dot status={status} />
    <span>{children || (status !== 'info' ? <FormattedMessage id={`notice.header.${status}`} /> : null)}</span>
  </Flex>
);

const Styles = {
  Dot: styled.span<{ status: Status }>`
    vertical-align: middle;
    height: 12px;
    flex: 0 0 12px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.status !== 'info' ? props.theme.palette.status[props.status].main : props.theme.palette.grey[100]};
  `,
};
