import styled from '@emotion/styled';
import * as React from 'react';
import { Icon, IconName, IconProps } from './Icon';
import { Status } from './StatusDot';

type Props = {
  status?: Status;
  rounded?: boolean;
  className?: string;
  color?: IconProps['color'];
};

const statusIconNames: { [key in Status]: IconName } = {
  info: 'info',
  success: 'check',
  warning: 'exclamation-triangle',
  error: 'exclamation-circle',
};

const statusIconNamesRounded: { [key in Status]: IconName } = {
  info: 'info-circle',
  success: 'check-circle',
  warning: 'exclamation-triangle',
  error: 'exclamation-circle',
};

export const StatusIcon: React.FC<Props> = ({ status = 'info', rounded = false, className, color }) => (
  <Styles.Icon
    fixedWidth
    icon={rounded ? statusIconNamesRounded[status] : statusIconNames[status]}
    color={color || (status === 'info' ? 'primary.500' : `status.${status}.main`)}
    className={className}
  />
);

const Styles = {
  Icon: styled(Icon)`
    font-size: ${({ theme }) => theme.typography.fontSizes.xl3};
  `,
};
