import * as React from 'react';
import { useDebounce } from 'react-use';

export function useDebouncedValue<T>(value: T, delayInMs: number) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    delayInMs,
    [value],
  );

  return debouncedValue;
}
