import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Theme } from '@txt/core.styles';
import { TooltipInlineBlock } from '@txt/core.ui/tooltip';
import * as React from 'react';
import { FormattedMessage } from '../i18n';
import { FormattedPrice, Props as FormattedPriceProps, UnknownPrice } from './FormattedPrice';

type Props = FormattedPriceProps & {
  /** Render the price as a range e.g. $12.00 - $14.00 */
  maxPriceRange?: number;
  /** Render a $0.00 price or a $? question mark. Defaults to false. */
  renderZero?: boolean;
  /** Certain parts of the pricing are unknown. */
  hasUnknownPricing?: boolean;
  /** Larger text styles */
  large?: boolean;
};

export const FormattedPricePerMonth: React.FC<Props> = ({
  price,
  maxPriceRange,
  renderZero = false,
  hasUnknownPricing = false,
  large = false,
  ...props
}) => {
  // Renders the common price, 0 or a $? based on `renderZero`.
  const renderPrice = price === 0 && !renderZero ? <UnknownPrice /> : <FormattedPrice price={price} {...props} />;

  // Renders the max price range addition, 0 or a $? based on `renderZero`.
  const renderMaxPrice =
    maxPriceRange === 0 && !renderZero ? <UnknownPrice /> : <FormattedPrice price={maxPriceRange ?? 0} {...props} />;

  const shouldRenderMaxPrice = typeof maxPriceRange !== 'undefined' && maxPriceRange !== price;

  // Edge case for completely unknown pricing.
  if (price === 0 && hasUnknownPricing) {
    return (
      <span css={large && largePriceStyles}>
        <UnknownPrice />
        {shouldRenderMaxPrice && <> - {renderMaxPrice}</>}
      </span>
    );
  }

  return (
    <span css={large && largePriceStyles}>
      {renderPrice}
      {shouldRenderMaxPrice && <> - {renderMaxPrice}</>}

      {hasUnknownPricing && (
        <>
          {' '}
          <Styles.UnknownPricing
            content={<FormattedMessage id="intl.FormattedPricePerMonth.pricePartsMissing" />}
            place="top"
          >
            <span>+?</span>
          </Styles.UnknownPricing>
        </>
      )}

      <FormattedMessage id="price.perMonth" />
    </span>
  );
};

const largePriceStyles = (theme: Theme) => css`
  font-size: ${theme.typography.fontSizes.xl5};
  font-weight: ${theme.typography.fontWeights.semiBold};
  letter-spacing: 0.2px;
  margin-bottom: ${theme.space[1]};
  white-space: nowrap;
`;

const Styles = {
  UnknownPricing: styled(TooltipInlineBlock)`
    color: ${(props) => props.theme.palette.grey[300]};
  `,
};
