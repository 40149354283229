export class QueryablePromise<T> {
  public isResolved = false;
  public isRejected = false;
  public promise: Promise<T>;
  public result: T | undefined = undefined;
  public error: any;

  get isFulfilled() {
    return this.isResolved || this.isRejected;
  }

  constructor(promise: Promise<T>) {
    this.promise = promise;
    this.promise.then(
      (result: T) => {
        this.isResolved = true;
        this.result = result;
        delete this.error;
      },
      (error) => {
        this.isRejected = true;
        this.error = error;
        delete this.result;
      },
    );
  }
}

interface Error {
  isCanceled: boolean;
}

export type CancelablePromiseError = {
  isCanceled: true;
};

/**
 * A promise wrapper which can be canceled to prevent e.g. state
 * updates on unmounted components.
 */
export class CancelablePromise<T> {
  private readonly innerPromise: Promise<T>;
  private readonly outerPromise: Promise<T>;
  private canceled: boolean;

  constructor(promise: Promise<T>) {
    this.innerPromise = promise;
    this.outerPromise = new Promise<T>((resolve, reject) => {
      this.buildPromise(resolve, reject);
    });
    this.canceled = false;
  }

  public cancel(): void {
    this.canceled = true;
  }

  public isCanceled(): boolean {
    return this.canceled;
  }

  private buildPromise(resolve: (v: T) => void, reject: (reason: Error) => void): void {
    this.innerPromise
      .then((value: T) => {
        if (!this.isCanceled()) {
          // accept
          resolve(value);
        } else {
          // reject due to cancellation
          reject({ isCanceled: true });
        }
      })
      .catch((error) => {
        if (this.isCanceled()) {
          // reject due to cancellation
          reject({ isCanceled: true });
        } else {
          // reject due to error
          reject(error);
        }
      });
  }

  public then<TResult>(
    onfulfilled?: (value: T) => TResult | Promise<TResult>,
    onrejected?: (reason: any) => T | Promise<T>,
  ) {
    return this.outerPromise.then(onfulfilled, onrejected);
  }

  public catch<TResult>(handler: (reason: any) => TResult | Promise<TResult>) {
    return this.outerPromise.catch(handler);
  }
}

export function cancelable<T>(promise: Promise<T>): CancelablePromise<T> {
  if (promise instanceof CancelablePromise) {
    return promise;
  }
  return new CancelablePromise<T>(promise);
}

export function defaultOnError<T>(promise: Promise<T>, alternative: T): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    promise.then(resolve).catch((e) => {
      resolve(alternative);
    });
  });
}

export function isThenable(x: any): x is { then: () => void } {
  return !!x && x instanceof Object && 'then' in x && x.then !== undefined && typeof x.then === 'function';
}

/**
 * "sleep" for async functions
 * the returned promise resolves after the given timeout
 *
 * use as `await sleep(1000)`
 */
export function sleep(timeout: number) {
  return new Promise((resolve, reject) => {
    window.setTimeout(resolve, timeout);
  });
}

export function isCancelablePromiseError(e: unknown): e is CancelablePromiseError {
  return !!(e && typeof e === 'object' && 'isCanceled' in e && typeof (e as any).isCanceled === 'boolean');
}

/** Returns true if the error object is a canceled error. */
export function isErrorCanceled(ex: any): boolean {
  return isCancelablePromiseError(ex) && ex.isCanceled === true;
}

/**
 * Executes a promise-returning function with retry logic, allowing configurable retries and backoff.
 *
 * This function attempts to resolve the provided asynchronous operation (`fn`) up to a specified number of retries. If
 * the function fails, it waits for a configurable backoff duration before retrying. The backoff duration can be
 * dynamically adjusted based on the retry count, enabling exponential backoff or other strategies.
 *
 * @template T - The type of the value resolved by the promise.
 * @param fn - A function that returns a promise to execute. This function will be retried on failure.
 * @param options.retries - The maximum number of retries before rejecting (default: 3).
 * @param options.backoff - A function determining the backoff duration (in milliseconds) after each failure. It
 *                          receives the retry attempt number as an argument (default: 1000ms).
 * @returns A promise that resolves with the result of `fn` or rejects after exceeding the retry limit.
 *
 * @example
 * retry(() => fetch('/api/resource'), {
 *   retries: 5,
 *   backoff: (retry) => 500 * Math.pow(2, retry), // Exponential backoff
 * }).then(response => console.log('Success!', response))
 *   .catch(error => console.error('Failed after retries', error));
 */
export async function retryAsync<T>(
  fn: () => Promise<T>,
  {
    retries = 3,
    backoffStrategy = () => 1000,
  }: { retries?: number; backoffStrategy?: (attempt: number) => number } = {},
): Promise<T> {
  const limit = retries + 1;
  for (let attemptsCount = 0; attemptsCount < limit; attemptsCount++) {
    try {
      return await fn();
    } catch (e) {
      if (attemptsCount === retries) {
        throw e;
      }
      await sleep(backoffStrategy(attemptsCount + 1));
    }
  }

  throw new Error('Exceeded maximum retry attempts');
}
