import * as React from 'react';
import { IconName } from '../Icon';
import { Button } from './Button';

type Props = React.ComponentProps<typeof Button> & {
  icon?: IconName;
  isLoading: boolean;
};

const ButtonLoading: React.FC<Props> = ({ icon, isLoading, children, disabled, ...props }) => (
  <Button {...props} disabled={isLoading || disabled}>
    {isLoading ? (
      <Button.Icon icon="spinner" fixedWidth className="fa-pulse fa-3x fa-fw" />
    ) : (
      icon && <Button.Icon fixedWidth icon={icon} />
    )}
    {children}
  </Button>
);

export const ButtonIconLoading: React.FC<Overwrite<Props, { icon: IconName }>> = (props) => (
  <ButtonLoading {...props} />
);

export const AsyncButton: React.FC<Omit<Props, 'isLoading'> & { onClick: () => Promise<any> }> = ({
  onClick,
  icon,
  ...props
}) => {
  const [isSaving, setIsSaving] = React.useState(false);

  const handleClick = async () => {
    try {
      setIsSaving(true);
      await onClick();
    } finally {
      setIsSaving(false);
    }
  };

  return <ButtonLoading onClick={handleClick} isLoading={isSaving} icon={icon} {...props} />;
};
