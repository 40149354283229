import styled from '@emotion/styled';
import { assert } from '@txt/core.utils/assert';
import { getFormattedErrorMessage } from '@txt/core.utils/error';
import * as React from 'react';
import { FormattedMessage } from './i18n';
import { Notice } from './Notice';

type Props = {
  heading?: React.ReactNode;
  actions?: React.ReactNode;
} & ({ children: any } | { error: Error });

export const ErrorDisplay: React.FC<Props> = ({ heading, actions, ...childrenOrError }) => {
  const error = 'error' in childrenOrError ? childrenOrError.error : childrenOrError.children;

  return (
    <ErrorRoot error={error} defaultTitle={<FormattedMessage id="asyncComponent.loadingFailed" />}>
      <Notice noMargin variant="error" actions={actions} heading={heading || <ErrorRoot.Title />}>
        <ErrorRoot.Message />
      </Notice>
    </ErrorRoot>
  );
};

const ErrorContext = React.createContext<{ title?: React.ReactNode; message: React.ReactNode } | null>(null);

const ErrorRootComponent: React.FC<{ children: React.ReactNode; defaultTitle: React.ReactNode; error: unknown }> = ({
  error,
  children,
  defaultTitle,
}) => {
  const { title = defaultTitle, errMsg: message } = extractMsgContent(error);
  return <ErrorContext.Provider value={{ title, message }}>{children}</ErrorContext.Provider>;
};

const useErrorContext = () => {
  const ctx = React.useContext(ErrorContext);
  assert(ctx, 'ErrorContext not defined. useErrorContext must be used within ErrorRoot');
  return ctx;
};

const ErrorTitle: React.FC = () => useErrorContext().title;
const ErrorMessageCmp: React.FC = () => useErrorContext().message;

export const ErrorRoot = Object.assign(ErrorRootComponent, {
  Title: ErrorTitle,
  Message: ErrorMessageCmp,
});

/**
 * Try to display the error (can either be a react component, text, error object, ...)
 */
function extractMsgContent(err: any): {
  title?: string;
  errMsg: React.ReactNode;
} {
  if (React.isValidElement(err)) {
    return {
      errMsg: err,
    };
  }
  try {
    return getFormattedErrorMessage(err);
  } catch (ex) {
    // can happen if e.g. there are nested object data structures
    console.error('Error while displaying error msg', ex);
    return {
      errMsg: 'Unknown error',
    };
  }
}

export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.palette.status.error.main};
`;
