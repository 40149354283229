import * as React from 'react';
import { FormatNumberOptions as FormatNumberOptionsIntl, FormattedNumber as FormattedNumberIntl } from 'react-intl';
import { getStaticIntl } from '../IntlProvider';

type FormattedNumberProps = React.ComponentProps<typeof FormattedNumberIntl>;

type Props = Omit<FormattedNumberProps, 'style'> & {
  className?: string;
  style?: FormattedNumberProps['style'] | 'smart-percent';
};

function handleSmallPercent(value: number) {
  if (value > 0 && value < 0.01) {
    return '<1%';
  }
  if (value < 0 && value > -0.01) {
    return '>-1%';
  }
  return null;
}

export const FormattedNumber: React.FC<Props> = ({ className, value, style, ...opts }) => {
  if (style === 'smart-percent') {
    const smallPercent = handleSmallPercent(value);

    if (smallPercent) {
      return <span className={className}>{smallPercent}</span>;
    }

    return (
      <span className={className}>
        <FormattedNumberIntl {...opts} value={value} style="percent" roundingMode={opts.roundingMode ?? 'halfCeil'} />
      </span>
    );
  }

  return (
    <span className={className}>
      <FormattedNumberIntl value={value} style={style} {...opts} />
    </span>
  );
};

type FormatNumberOptions = Omit<FormatNumberOptionsIntl, 'style'> & {
  style?: FormatNumberOptionsIntl['style'] | 'smart-percent';
};

/** String only number renderer. */
export function formatNumber(value: number, { style, ...opts }: FormatNumberOptions = {}): string {
  if (style === 'smart-percent') {
    const smallPercent = handleSmallPercent(value);
    if (smallPercent) {
      return smallPercent;
    }
    return getStaticIntl().formatNumber(value, {
      ...opts,
      style: 'percent',
      roundingMode: opts.roundingMode ?? 'halfCeil',
    });
  }
  return getStaticIntl().formatNumber(value, { style, ...opts });
}
