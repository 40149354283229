import styled from '@emotion/styled';
import { TooltipInlineBlock } from '@txt/core.ui/tooltip';
import { convertCurrency, Currency, currencySymbol, defaultCurrency } from '@txt/core.utils/conversion';
import { useUserCurrency } from '@txt/core.utils/hooks';
import * as React from 'react';
import { displayCurrency, RoundingMode } from '../displayCurrency';
import { formatMessage, FormattedMessage } from '../i18n';

export type Props = {
  /** Price in USD required. */
  price: number;
  rounding?: RoundingMode;
  showSymbol?: boolean;
  className?: string;
  perMonth?: boolean;
  /** defaults to USD. */
  fromCurrency?: Currency;
};

/**
 * Renders a price value wrapped with a tooltip that displays the original source value (USD).
 */
export const FormattedPrice: React.FC<Props> = ({
  price,
  perMonth,
  rounding,
  showSymbol,
  className,
  fromCurrency = defaultCurrency,
}) => {
  const [currency] = useUserCurrency();

  return (
    <TooltipInlineBlock
      className={`formattedPrice ${className ?? ''}`}
      disabled={currency === fromCurrency}
      place="top"
      content={
        <Styles.TooltipContent>
          <FormattedMessage id="price.conversion1" values={{ currency, fromCurrency }} />

          <br />

          <FormattedMessage
            id="price.conversion2"
            values={{
              // Force default currency here for comparison.
              price: displayCurrency({ value: price, currency: fromCurrency, showSymbol, rounding }),
            }}
          />
        </Styles.TooltipContent>
      }
    >
      <span>
        <span>
          {displayCurrency({
            value: convertCurrency(price, fromCurrency, currency),
            currency,
            showSymbol,
            rounding,
          })}
        </span>

        {perMonth && <FormattedMessage id="price.perMonth" values={{ currency, fromCurrency }} />}
      </span>
    </TooltipInlineBlock>
  );
};

type UnknownPriceProps = {
  content?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
};

export const UnknownPrice: React.FC<UnknownPriceProps> = ({ children, content, className }) => {
  const [currency] = useUserCurrency();

  return (
    <TooltipInlineBlock content={content ?? <FormattedMessage id="price.unknown" />} place="top" className={className}>
      <span>{children || `${currencySymbol[currency]}?`}</span>
    </TooltipInlineBlock>
  );
};

const Styles = {
  TooltipContent: styled.div`
    text-align: center;
    line-height: 1.6;
  `,
};

/**
 * Render a price value as string, perfect for svg or charts, otherwise, always use the above FormattedPrice!
 */
export const formattedPrice = ({
  price,
  perMonth,
  rounding,
  showSymbol,
  fromCurrency = defaultCurrency,
  currency,
  compact,
}: Props & { currency: Currency; compact?: (convertedPrice: number) => boolean }) => {
  const convertedValue = convertCurrency(price, fromCurrency, currency);

  const result = displayCurrency({
    value: convertedValue,
    currency,
    rounding,
    compact: compact?.(convertedValue),
    showSymbol,
  });

  if (perMonth) {
    return `${result}${formatMessage('price.perMonth')}`;
  }

  return result;
};
