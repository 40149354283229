export const en = {
  'button.OK': 'OK',
  'button.Cancel': 'Cancel',
  'button.Yes': 'Yes',
  'button.No': 'No',
  'button.Save': 'Save',
  'button.Discard': 'Discard',
  'button.Confirm': 'Confirm',
  'date.todayAt': 'Today at',
  'date.yesterdayAt': 'Yesterday at',
  'price.conversion1': '{fromCurrency} to {currency} conversion.',
  'price.conversion2': 'Converted from {price}',
  'price.perMonth': '/mo',
  'price.unknown': 'Pricing data is not available.',
  'intl.FormattedPricePerMonth.pricePartsMissing':
    'Pricing data is not available for all included products. The actual price might be higher.',
};
