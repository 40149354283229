import moment, { locale as momentSetLocale } from 'moment';
import * as React from 'react';
import * as reactIntl from 'react-intl';

const cache = reactIntl.createIntlCache();

type CustomIntlShape = reactIntl.IntlShape & {
  formatNumberDefault: reactIntl.IntlShape['formatNumber'];
};

type MessageDefs = Record<string, string>;

const registeredMessages: { en: MessageDefs; de: MessageDefs } = {
  en: {},
  de: {},
};

export const supportedLanguagesDefinitions = [
  { key: 'en', label: 'English' },
  { key: 'de', label: 'Deutsch' },
] as const;

export const supportedDateFormats = [
  { key: 'DD/MM/YYYY', label: 'Day/Month/Year', value: 'DD/MM/YYYY' },
  { key: 'DD.MM.YYYY', label: 'Day.Month.Year', value: 'DD.MM.YYYY' },
  { key: 'MM/DD/YYYY', label: 'Month/Day/Year', value: 'MM/DD/YYYY' },
] as const;

export const supportedTimeFormats = [
  { key: '24-hour', label: '24 Hour', value: 'HH:mm' },
  { key: '12-hour', label: '12 Hour', value: 'hh:mm A' },
] as const;

export const supportedNumberFormats = [
  { key: '1,000.00', label: '1,000.00 (en-US)', locale: 'en-US' },
  { key: '1.000,00', label: '1.000,00 (de-DE)', locale: 'de-DE' },
  { key: '1 000,00', label: '1 000,00 (de-AT)', locale: 'de-AT' },
] as const;

export type SupportedLangs = (typeof supportedLanguagesDefinitions)[number]['key'];
type SupportedDateFormats = (typeof supportedDateFormats)[number]['key'];
type SupportedTimeFormats = (typeof supportedTimeFormats)[number]['key'];
export type SupportedNumberFormats = (typeof supportedNumberFormats)[number]['key'];

export type IntlCustomConfigurationOptions = {
  locale: SupportedLangs;
  dateFormat: SupportedDateFormats;
  timeFormat: SupportedTimeFormats;
  numberFormat: SupportedNumberFormats;
};

export const defaultIntlConfiguration: IntlCustomConfigurationOptions = {
  locale: supportedLanguagesDefinitions[0].key,
  dateFormat: supportedDateFormats[0].key,
  timeFormat: supportedTimeFormats[0].key,
  numberFormat: supportedNumberFormats[0].key,
};

/**
 * static intl instance so that we can call formatMessage without component stack.
 * Initialize without messages so that we have an instance also during testing.
 */
let intl: { instance: reactIntl.IntlShape; options: IntlCustomConfigurationOptions } =
  createIntlInstance(defaultIntlConfiguration);

export function getStaticIntl(): CustomIntlShape {
  return intl.instance as CustomIntlShape;
}

export function getStaticIntlOptions() {
  return intl.options;
}

function createIntlInstance(options: IntlCustomConfigurationOptions) {
  const messages = options.locale === 'de' ? registeredMessages.de : registeredMessages.en;
  const instance = reactIntl.createIntl(
    { locale: options.locale, messages, textComponent: 'span' },
    cache,
  ) as CustomIntlShape;

  // User specific settings override.
  if (options.dateFormat) {
    instance.formatDate = (date) =>
      moment(date).format(supportedDateFormats.find((x) => x.key === options.dateFormat)?.value);
  }

  if (options.timeFormat) {
    instance.formatTime = (date) =>
      moment(date).format(supportedTimeFormats.find((x) => x.key === options.timeFormat)?.value);
  }

  if (options.numberFormat) {
    instance.formatNumberDefault = instance.formatNumber.bind(undefined);
    instance.formatNumber = (value, opts): string => {
      const numberLocale = supportedNumberFormats.find((x) => x.key === options.numberFormat)?.locale;

      if (numberLocale) {
        // We need to create a custom intl instance here to render different number formats.
        return reactIntl.createIntl({ locale: numberLocale }).formatNumber(value, opts);
      } else {
        return instance.formatNumberDefault(value, opts);
      }
    };
  }

  return { instance, options };
}

/** Register new i18n keys. Use this to add new keys in modules. */
export function registerI18Messages(newMessages: { en: MessageDefs; de?: MessageDefs }) {
  Object.assign(registeredMessages.en, newMessages.en);
  Object.assign(registeredMessages.de, newMessages.en); // use en keys as default

  if (newMessages.de) {
    Object.assign(registeredMessages.de, newMessages.de);
  }

  intl = createIntlInstance(intl.options);
}

function setLocale(options: IntlCustomConfigurationOptions) {
  if (
    options.locale !== intl.options.locale ||
    options.dateFormat !== intl.options.dateFormat ||
    options.timeFormat !== intl.options.timeFormat ||
    options.numberFormat !== intl.options.numberFormat
  ) {
    intl = createIntlInstance(options);
    momentSetLocale(options.locale);
  }
}

type Props = React.PropsWithChildren<Partial<IntlCustomConfigurationOptions>>;

/** React-intl provider which uses our global static intl instance. */
export const TxtIntlProvider: React.FC<Props> = ({ children, ...props }) => {
  // dummy state to ensure that the component is re-rendered on locale change
  const [currentIntlInstance, setCurrentIntl] = React.useState(intl.instance);

  React.useEffect(() => {
    setLocale({ ...defaultIntlConfiguration, ...props });
    setCurrentIntl(intl.instance);
  }, [props]);

  return <reactIntl.RawIntlProvider value={currentIntlInstance}>{children}</reactIntl.RawIntlProvider>;
};

export function useLocale() {
  return reactIntl.useIntl().locale as SupportedLangs;
}
