import * as React from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: React.ReactNode;
  /** defaults to document.body */
  container?: HTMLElement;
};

export const Portal: React.FC<Props> = ({ children, container }) => {
  return createPortal(children, container ?? document.body);
};
