import * as Immutable from 'immutable';

/** Creates a map from an arbitrary collection. */
export function createImmutableMap<K, V>(collection: Iterable<V>, getKey: (item: V) => K): Immutable.Map<K, V> {
  return Immutable.Map<K, V>().withMutations((map) => {
    for (const item of collection) {
      map.set(getKey(item), item);
    }
  });
}

/**
 * Creates a typed map from the given object
 *
 * this is just a simple wrapper around the Map({}) constructor to get a typed key
 */
export function createImmutableMapFromObject<V, T extends { [k: string]: V }>(obj: T) {
  type Key = {
    [P in keyof T]: P;
  }[keyof T & string];
  return Immutable.Map<Key, T[Key]>(obj as any);
}

export function setManyInImmutableMap<K, V>(map: Immutable.Map<K, V>, items: [K, V][]) {
  return map.withMutations((mutableMap) => {
    for (const item of items) {
      mutableMap.set(item[0], item[1]);
    }
  });
}

/**
 * Updates the value within the given map iff the key exists
 *
 * this is a noop if the key is not found
 *
 * @returns the updated map
 */
export function updateIfExists<K, V>(map: Immutable.Map<K, V>, key: K, updater: (value: V) => V): Immutable.Map<K, V> {
  if (!map.has(key)) {
    return map; // noop
  }

  return map.update(key, (node) => updater(node!));
}

/** Merges/reduces the given maps to a single map */
export function mergeImmutableMaps<K, V, V2 = V>(
  merge: (
    value: V,
    /** is undefined on first insert */
    accumulator: V2 | undefined,
  ) => V2,
  ...maps: Immutable.Map<K, V>[]
): Immutable.Map<K, V2> {
  return Immutable.Map<K, V2>().withMutations((output) => {
    for (const map of maps) {
      for (const [k, v] of map) {
        const others = output.get(k);
        const newValue = merge(v, others);
        output.set(k, newValue);
      }
    }
  });
}

export function hasAnyKey<T>(immutableSet: Immutable.Set<T>, keys: T[]) {
  return keys.some((key) => immutableSet.has(key));
}
